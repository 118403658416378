.wd-total-container {
  clear: both;
  position: relative;
}

.wd-total-container .wd-total-text {
  color: #555;
  float: right;
  font-size: 9pt;
  margin: 10px auto;
  padding: 0;
  padding-bottom: 50px;
  text-transform: capitalize;
}
