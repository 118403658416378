.App-Notification {
    width: 350px;
    height: 280px;
    background-color: #fff;
    box-shadow: 0 0 13px 0 rgba(82,63,105,0.1);
    margin-right: 20px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    visibility: hidden;
    opacity: 0;
    transition: .4s margin, 0.4s opacity;
    margin-top: 20px;
    position: fixed;
    top: 58.55px;
    right: 50px;
}

.App-Notification.show {
    visibility: visible;
    opacity: 1;
    margin-top: -1px;
    z-index: 999;
}

.App-Notification .header {
    background-color: #f8f9fa;
    height: 24px;
    font-size: 10pt;
    border-bottom: 1px solid #ddd;
    padding: 8px 10px;
    display: flex;
    align-items: center;
    justify-content: left;
    color: #222;
    font-weight: bold;
}

.App-Notification .content {
    font-size: 9pt;
    padding: 4px 0;
    height: 226px;
    overflow-y: auto;
}

.App-Notification .content .item {
    font-size: 9pt;
    padding: 10px;
}

.App-Notification .content .item:hover {
    cursor: pointer;
    background-color: #f4f4f4;
}
