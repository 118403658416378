.login {
  background-image: -webkit-linear-gradient(260deg,#fcfcfc 40%, rgb(232, 78, 28) 120%);
  height: 100vh;
  min-height: 100vh;
  overflow-y: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .login-box {
  background:#FBFBFA;
  background-image: -webkit-linear-gradient(180degree, #FBFBFA 20%, #ffffff55 100%);
  margin: 0 auto;
  height: 60vh;
  width: 35vw;
  /* border: 5px solid #e84e1c66; */
  border-radius: 20px;
  -webkit-box-shadow: -1px 2px 50px -1px rgb(232, 78, 28, 0.3);
  -moz-box-shadow: -1px 2px 50px -1px rgb(232, 78, 28, 0.3);
  box-shadow: -1px 2px 50px -1px rgba(232, 78, 28, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .login-left-side {
  width: 100%;
  height: 100%;
  text-align: center;
  margin-bottom: 25%;
}

.login .description {
  margin: -40px 0 30px 0;
  font-weight: bold;
  letter-spacing: 2px;
}

.login .login-pic {
  width: 85%;
  margin: 90px 70px;
  opacity: 0.9;
}

.login .box {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* margin: 8% 0px 30px; */
}

.login .logo {
  height: auto;
  width: 100px;
  padding: 20px 0px;
  margin: 20px 20px 20px 0px;
}

.login .center {
  text-align: center;
  width: 100%;
}

.login .row-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.login .title {
  font-size: 24pt;
  color: var(--themeColor);
  font-weight: bold;
}

.login .title-sub {
  font-size: 20pt;
  color: var(--themeColor);
  font-weight: bold;
}

.login .container {
  text-align: left;
}

.login .input-text {
  margin-top: 20px;
  text-align: left;
  width: 100%;
}

.login .alert {
  margin-bottom: 12px;
  text-align: left;
}

.login .button {
  width: 80%;
}

.login .copyright {
  padding: 7px 20px;
  font-size: 8.5pt;
  color: #dedede;
  width: 100%;
  position: absolute;
  bottom: 10px;
  text-align: center;
}

.login .app-logo {
  width: 100px;
  height: 33.333px;
  margin: 0px 1px -4px 1px;
  border-radius: 6px;
  vertical-align: baseline;
}

@media screen and (max-width: 1200px) {
  .login .login-box {
    width: 55vw;
    height: 65vh;
  }
}

@media screen and (max-width: 960px) {
  .login .login-box {
    width: 70vw;
    height: 65vh;
  }
}

@media screen and (max-width: 720px) {
  .login .login-box {
    width: 80vw;
    height: 65vh;
  }
}

@media screen and (max-width: 480px) {
  /* .login .box {
    margin: 0 auto;
    padding: 0 30px;
    border-radius: 15px;
  }

  .login .login-box {
    margin-top: 30px;
    width: calc(100% / 1.15);
  }

  .login .login-left-side {
    display: none;
  } */
}

@media screen and (max-width: 400px),
(max-width: 480px) and (max-height: 680px) {
  /* .login {
    background-color: #fff;
    padding: 0;
  }

  .login .login-box {
    background: none;
    min-width: 240px;
    width: 320px;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .login .logo {
    margin: 40px;
    width: 240px;
  } */
}

