.App-MenuSide {
  width: 220px;
  max-width: 220px;
  background-color: #fff;
  box-shadow: 0 5px 13px 5px rgba(82,63,105,0.3);
  margin-right: 20px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 0px solid #fff;
  border-radius: 4px;
  opacity: 0;
  position: fixed;
  top: 56px;
  right: 10px;
}

.App-MenuSide.show {
  transition: .4s margin, 0.4s opacity;
  margin-top: -1px;
  visibility: visible;
  opacity: 1;
  z-index: 999;
}

.App-MenuSide.hide {
  -webkit-transition: 0.4s ease;
  -moz-transition: 0.4s ease;
  -ms-transition: 0.4s ease;
  -o-transition: 0.4s ease;
  transition: 0.4s ease;
  visibility: hidden;
  margin-top: -20px;
  opacity: 0;
  z-index: 0;
}

.App-MenuSide .header {
  background-color: #f29579;
  height: 30px;
  font-size: 9pt;
  border-bottom: 1px solid #ddd;
  border-radius: 4px 4px 0 0;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
}

.App-MenuSide .content {
  font-size: 9pt;
  padding: 8px;
}

.App-MenuSide .content .row {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.App-MenuSide .item-title {
  font-size: 10pt;
  padding: 8px 18px 8px 8px;
  color: var(--themeColor);
  text-transform: uppercase;
  font-weight: 600;
  background-color: #fff;
  width: 100%;
  text-align: end;
  cursor: default;
  border-radius: 4px;
}

.App-MenuSide .item-title:hover {
  background-color: #e84e1c33;
  cursor: pointer;
}
