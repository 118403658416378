.wd-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wd-upload-uploader {
  border: 1px dashed #a8a8a8;
  border-radius: 6px;
  cursor: pointer;
  height: 150px;
  width: 250px;
  text-align: center;
}

.wd-upload-image {
  max-height: 100px;
  max-width: 150px;
  position: absolute;
  top: -25px;
  left: -50px;
  z-index: 0;
}
