@formRowPrefixCls: witty-form-row;

.@{formRowPrefixCls} {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding: 10px 0;
}

.@{formRowPrefixCls} .label{
    font-size: 11pt;
    font-weight: bold;
    color: #444;
}
@primary-color: #e84e1c;@font-size-base: 13px;