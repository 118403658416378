:root {
    --notification-width: 320px;
    --success: #4AC4DC;
    --warning: #fba742;
    --error: #ffafaf;
}

.notification-container {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-Index: -1;
}

.notification-box {
    min-width: var(--notification-width);
    min-height: 50px;
    padding: 12px 20px;
    z-Index: 1000;
    border-radius: 9px;
    font-size: 10pt;
    position: fixed;
    background-color: #fff;
    visibility: hidden;

    display: flex;
    flex-direction: row;
    flex:1 ;

    -webkit-animation: in-out 2s 1; /* Chrome, Safari, Opera */
    animation: in-out 2s 1;
    -webkit-box-shadow: 0px 3px 12px 1px rgba(0,0,0,.1);
    -moz-box-shadow: 0px 3px 12px 1px rgba(0,0,0,.1);
    box-shadow: 0px 3px 12px 1px rgba(0,0,0,.1);
}

.notification-box-success {
    -webkit-animation: in-out 2s 1; /* Chrome, Safari, Opera */
    animation: in-out 2s 1;
}

.notification-box-warning {
  -webkit-animation: in-out-warning 2s 1; /* Chrome, Safari, Opera */
  animation: in-out-warning 2s 1;
}

.notification-box-error {
    -webkit-animation: in-out-error 2s 1; /* Chrome, Safari, Opera */
    animation: in-out-error 2s 1;
}

/* Chrome, Safari, Opera */
@-webkit-keyframes in-out {
    0%   {background: var(--success); left: calc(100%/2 - var(--notification-width)/2); top: 0px; visibility: visible; opacity: 0.5;}
    20%  {background: var(--success); left: calc(100%/2 - var(--notification-width)/2); top: 40px; opacity: 1;}
    90%  {background: var(--success); left: calc(100%/2 - var(--notification-width)/2); top: 40px; opacity: 1;}
    100% {background: var(--success); left: calc(100%/2 - var(--notification-width)/2); top: 0px; visibility: hidden; opacity: 0;}
}

@keyframes in-out {
    0%   {background: var(--success); left: calc(100%/2 - var(--notification-width)/2); top: 0px; visibility: visible; opacity: 0.5;}
    20%  {background: var(--success); left: calc(100%/2 - var(--notification-width)/2); top: 40px; opacity: 1;}
    90%  {background: var(--success); left: calc(100%/2 - var(--notification-width)/2); top: 40px; opacity: 1;}
    100% {background: var(--success); left: calc(100%/2 - var(--notification-width)/2); top: 0px; visibility: hidden; opacity: 0;}
}

@keyframes in-out-warning {
  0%   {background: var(--warning); left: calc(100%/2 - var(--notification-width)/2); top: 0px; visibility: visible; opacity: 0.5;}
  20%  {background: var(--warning); left: calc(100%/2 - var(--notification-width)/2); top: 40px; opacity: 1;}
  90%  {background: var(--warning); left: calc(100%/2 - var(--notification-width)/2); top: 40px; opacity: 1;}
  100% {background: var(--warning); left: calc(100%/2 - var(--notification-width)/2); top: 0px; visibility: hidden; opacity: 0;}
}

@keyframes in-out-error {
    0%   {background: var(--error); left: calc(100%/2 - var(--notification-width)/2); top: 0px; visibility: visible; opacity: 0.5;}
    20%  {background: var(--error); left: calc(100%/2 - var(--notification-width)/2); top: 40px; opacity: 1;}
    90%  {background: var(--error); left: calc(100%/2 - var(--notification-width)/2); top: 40px; opacity: 1;}
    100% {background: var(--error); left: calc(100%/2 - var(--notification-width)/2); top: 0px; visibility: hidden; opacity: 0;}
}

.notification-box .icon {
    font-size: 22pt;
    color: #fff;
    padding-right: 8px;
    font-weight: bold;
}

.notification-box .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: space-around;
}

.notification-box .title {
    font-weight: bold;
    font-size: 11pt;
    color: #fff;
}

.notification-box .message {
    color: #fff;
}
