@import '../node_modules/normalize.css/normalize.css';
@import './components/witty-ui/index.less';
@import '~antd/dist/antd.less';
@font-family: 'Nunito', 'sans-serif', 'Helvetica Neue For Number';

:root {
  --themeColor: #e84e1c;
  --themeColorAlt: rgb(198, 145, 19);
  --themeColorLite: rgba(11, 94, 183, 0.2);
  --themeColorDark: rgb(25, 147, 141);
  --themeFontColor: #444;
  --themeBackgroundLightColor: rgba(170, 23, 27, 0.03);
  --themeMenuWidth: 210px;
  --themeMenuBackground:#fff;
  --themeMenuBackgroundDark:#f4f8fc;
  --themeMenuBorder: rgb(198, 145, 19);
  --themeBorderColor: #C6D9E6;
  --themeBackground: #f4f8fc;
}

html {
  background-color: #f4f8fc;
}

body {
  color: #333;
  font-family: 'Nunito', sans-serif;
  font-size: 10pt;
  margin: 0;
  overflow-x: hidden;
  padding: 0;
}

.wd-dropdown-readonly .ant-btn:not(.ant-dropdown-trigger):hover {
  border-color: #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
  cursor: default;
}

.wd-list-item-subtitle {
  color: #999;
  font-size: 9pt;
}

@media screen and (max-width: 991px) {
  .wd-filter .ant-row > div {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 331px) {
  .wd-filter .ant-row a:not(:last-child) .wd-button {
    margin-bottom: 8px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1438px) {
  .wd-filter .ant-row a:not(:last-child) .wd-button {
    margin-bottom: 8px;
  }
}

@primary-color: #e84e1c;@font-size-base: 13px;