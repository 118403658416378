.wd-panel {
  background-color: white;
  /* border: 1px solid #C6D9E6; */
  border-radius: 3px;
  margin-bottom: 20px;
  min-height: 100px;
  padding: 5px;
  width: 100%;
  z-index: 10;
  margin: 0 5px 20px 0px;
  box-shadow: 0 0 16px -4px #f78f6f44;
  /* -webkit-box-shadow: 0px 0px 34px -8px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 34px -8px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 34px -8px rgba(0, 0, 0, 0.15); */
}

.wd-panel .wd-panel-title {
  border-bottom: 1px solid #f4f4f4;
  color: var(--themeColor);
  font-size: 11pt;
  font-weight: bold;
  margin: 8px 2px;
  min-height: 33px;
  padding: 0 8px 8px 8px;
}

.wd-panel .wd-panel-body {
  padding: 10px 20px;
}

.wd-panel .warn {
  background-color: pink;
}
