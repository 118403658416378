
.wd-loading {
/*     height: 100%; */
    width: 100%;
    z-index: 0;
}

.wd-loading .loading {
    height: 100%;
    width: 100%;
}

.wd-loading .loading-blur {
    filter: blur(2px);
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
}

.wd-loading .component {
    z-index: -1;
}

.wd-loading .icon {
    width: 100%;
    text-align: center;
    line-height: 150px;
    min-height: 200px;
}
