.dsh {

}

.dsh .subtitle {
  padding-top: 8px;
  font-size: 15px;
  color: #666;
  font-weight: 400;
}

.dsh .panel-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.dsh .panel-tc {
  cursor: pointer;
  border: 2px solid #e84e1c33;
  border-radius: 12px;
  background-color: #fff;
  min-width: 475px;
  height: 170px;
  margin: 10px 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.dsh .panel-tc:hover {
  cursor: pointer;
  border: 3px solid #e84e1c99;
}

.dsh .panel-tc .banner-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.dsh .panel-tc .banner-row .banner-img {
  width: 160px;
}

.dsh .panel-tc .banner-row .banner-col {
  width: 250px;
  padding-left: 12px;
}

.dsh .panel-tc .banner-row .banner-title {
  font-size: 16px;
  font-weight: 600;
}

.dsh .panel-tc .org-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 12px 0px 0px 0px;
}

.dsh .panel-tc .org-row .org-img {
  height: 22px;
}

.dsh .panel-tc .org-row .org-title {
  padding-left: 8px;
  font-size: 13px;
  font-weight: 600;
  color: #d19ba2;
}

.dsh .panel-tc .org-pos {
  margin-top: 8px;
  font-size: 13px;
  font-weight: 400;
  color: #db122d;
  font-style: italic;
}

.modal-tc {

}

.modal-tc .modal-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.modal-tc .modal-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.modal-tc .modal-title {
  font-weight: 600;
}

.modal-tc .banner-img {
  width: 300px;
}


@media screen and (max-width: 700px) {
  .dsh .panel-tc {
    min-width: calc(80vw);
  }
}
